import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { LinearProgress, Button, TextField } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useOnScreen } from '../../api/dataApi';
import { getCompany } from '../../api/companyDataApi';
import { round } from '../../api/offerCalc';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import { formatNumber, doCalcs } from '../CompanyProfile';
import RevenueCalc from '../CompanyProfile/RevenueCalc';

import { teams } from '../CompanyProfile/Team';

import './index.css';

const buttonHtml = `<div><!--[if mso]>
<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="{url}" target="_blank" style="height:40px;v-text-anchor:middle;width:200px;" arcsize="25%" stroke="f" fillcolor="#337eff">
  <w:anchorlock/>
  <center>
<![endif]-->
    <a href="{url}" target="_blank"
style="background-color:#337eff;border-radius:10px;color:#ffffff;display:inline-block;font-family:sans-serif;font-size:20px;font-weight:bold;line-height:40px;text-align:center;text-decoration:none;width:200px;-webkit-text-size-adjust:none;">Show Me More</a>
<!--[if mso]>
  </center>
</v:roundrect>
<![endif]--></div>`;

function CompanyEmail({ siteConfig, webSocket, actions }) {
  const loadProfileInitted = useRef();
  const loadingTimeout = useRef();
  const companyKey = useQueryParam('company', null);
  const ownerKey = useQueryParam('owner', null);

  const campaignRequested = useQueryParam('uc', null);
  const [company, setCompany] = useState(null);
  const [calcs, setCalcs] = useState(null);
  const [selectedSignature, setSelectedSignature] = useState('none');
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    if (
      (companyKey || ownerKey) &&
      webSocket.connected &&
      loadProfileInitted.current !== companyKey
    ) {
      loadProfileInitted.current = companyKey;
      loadingTimeout.current = setTimeout(() => {
        actions.pageLoading(true, 'Loading...');
      }, 300);
      getCompany(companyKey, ownerKey).then((_company) => {
        clearTimeout(loadingTimeout.current);
        setCompany(_company);
        actions.pageLoading(false);

        document.title =
          'Email: ' + siteConfig.brand + ' + ' + _company.ManagementCompanyName;
      });
    }
  }, [companyKey, webSocket, ownerKey]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (campaignRequested) {
      setCampaign(campaignRequested);
    }
  }, [campaignRequested]);

  useEffect(() => {
    if (company) {
      setCalcs(
        doCalcs({
          ...company,
          PercentTurn: company.PercentTurn * 100,
          PercentEngagement: company.PercentEngagement * 100
        })
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('calcs', calcs);
  }, [calcs]);

  function selectText(containerid) {
    if (document.selection) {
      // IE
      var range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select();
    } else if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  }

  function getLink() {
    return (
      'https://movingin.com/company-profile/?' +
      (campaign ? 'uc=' + campaign + '&' : '') +
      (selectedSignature
        ? (campaign ? '&' : '') + 'uf=' + selectedSignature + '&'
        : '') +
      'company=' +
      companyKey
    );
  }

  return (
    <div
      className={
        'company-page email' +
        (company && !isNaN(company.RevenueShareTotal) ? ' initted' : '')
      }>
      {company && calcs ? (
        <div className="actions">
          <TextField
            label={'User (Optional)'}
            placeholder={'Enter a name to use for click tracking'}
            value={campaign}
            onChange={(event) => setCampaign(event.target.value)}
            variant="outlined"
            style={{ marginBottom: '20px', maxWidth: '350px' }}
            fullWidth
          />

          <br />

          <Select
            variant="outlined"
            id="signature-select"
            value={selectedSignature}
            style={{ width: '200px' }}
            className="signature-menu"
            onChange={(event) => setSelectedSignature(event.target.value)}>
            {Object.keys(teams.sales.members).map((memberKey) => (
              <MenuItem value={memberKey}>
                {teams.sales.members[memberKey].name}
              </MenuItem>
            ))}
          </Select>

          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => {
              selectText('emailBody');
            }}>
            Select Email
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //document.execCommand('copy');

              var target = document.getElementById('emailBody');
              var range, select;
              if (document.createRange) {
                range = document.createRange();
                range.selectNode(target);
                select = window.getSelection();
                select.removeAllRanges();
                select.addRange(range);
                document.execCommand('copy');
                select.removeAllRanges();
              } else {
                range = document.body.createTextRange();
                range.moveToElementText(target);
                range.select();
                document.execCommand('copy');
              }
            }}>
            Copy to Clipboard
          </Button>
        </div>
      ) : null}

      {company && calcs ? (
        <div id="emailBody" className="email-body">
          <div className="email-container" align="center">
            <table
              width="100%"
              style={{ maxWidth: '600px' }}
              cellpadding="0"
              cellspacing="0"
              border="0"
              align="center">
              <tr>
                <td>
                  <h1 className="main-title">
                    <img
                      src="https://mi-public-content.s3.us-west-1.amazonaws.com/buildings.png"
                      style={{ width: '50px' }}
                      width="50"
                      align="center"
                      vspace="10"
                    />
                    <br />
                    {company.LogoFile ? (
                      <img
                        src={company.LogoFile}
                        alt={company.ManagementCompanyName}
                        style={{
                          height: 'auto',
                          width: 'auto',
                          maxWidth: '500px',
                          maxHeight: '60px'
                        }}
                        align="center"
                      />
                    ) : (
                      company.ManagementCompanyName
                    )}
                    <br /> <span className="math">+</span>
                    <br />
                    <img
                      src={
                        'https://mi-public-content.s3.us-west-1.amazonaws.com/MovingIn+Logo.png'
                      }
                      alt="MovingIN"
                      style={{ width: '300px' }}
                      width="300"
                      align="center"
                    />
                    <br />
                    <span className="math">=</span>
                    <br />
                    <span className="amount">
                      $
                      {formatNumber(
                        calcs && calcs.RevenueShareTotal
                          ? calcs.RevenueShareTotal
                          : 0,
                        0
                      )}
                    </span>
                  </h1>
                  <h2 className="sub-title">
                    That’s how much you can add to your bottom-line each year if
                    you partner with {siteConfig.brand}.
                  </h2>

                  <p>And this is how:</p>
                  <p>
                    Your new residents have a lot to deal with in the time
                    between signing their lease and actually moving into their
                    new home. They need to connect utilities, order internet,
                    secure renters insurance and so forth.
                  </p>
                  <p>
                    The {siteConfig.brand} online Resident Dashboard lets them
                    do all of this, and much more, all in one convenient place.
                    And when they do, oftentimes the provider of those services
                    will pay us a commission for processing the order.
                  </p>
                  <p>We then share 20% of that commission with you.</p>

                  <RevenueCalc
                    siteConfig={siteConfig}
                    company={company}
                    calcs={calcs}
                    formatNumber={formatNumber}
                    email
                  />

                  <div
                    align="center"
                    style={{ padding: '20px' }}
                    dangerouslySetInnerHTML={{
                      __html: buttonHtml.split('{url}').join(getLink())
                    }}></div>

                  {selectedSignature !== 'none'
                    ? [teams.sales.members[selectedSignature]].map(
                        (signature) => (
                          <div className="signature">
                            <table
                              width="100%"
                              cellpadding="5"
                              cellspacing="0"
                              border="0">
                              <tr>
                                <td valign="middle" align="left" width="100">
                                  <img
                                    src={signature.image}
                                    style={{
                                      width: '125px',
                                      height: '125px',
                                      marginRight: '20px',
                                      borderRadius: '100%',
                                      backgroundColor: signature.color
                                    }}
                                    width="80"
                                    align="left"
                                    alt={signature.name}
                                  />
                                </td>
                                <td valign="middle" align="left">
                                  <strong>{signature.name}</strong>
                                  {signature.subtitle ? (
                                    <>
                                      <br />
                                      {signature.subtitle}
                                    </>
                                  ) : null}
                                  {signature.title ? (
                                    <>
                                      <br />
                                      {signature.title}
                                    </>
                                  ) : null}
                                  {signature.phone ? (
                                    <>
                                      <br />
                                      <a
                                        href={'tel:' + signature.phone}
                                        style={{
                                          textDecoration: 'underline',
                                          color: '#337eff'
                                        }}>
                                        {signature.phone}
                                      </a>
                                    </>
                                  ) : null}
                                  {signature.email ? (
                                    <>
                                      <br />
                                      <a
                                        href={'mailto:' + signature.email}
                                        style={{
                                          textDecoration: 'underline',
                                          color: '#337eff'
                                        }}>
                                        {signature.email}
                                      </a>
                                    </>
                                  ) : null}
                                  {signature.linkedIn ? (
                                    <>
                                      <br />
                                      <a
                                        href={signature.linkedIn}
                                        style={{
                                          textDecoration: 'underline',
                                          color: '#337eff'
                                        }}>
                                        {signature.linkedIn
                                          .split('https://www.')
                                          .join('')}
                                      </a>
                                    </>
                                  ) : null}
                                </td>
                              </tr>
                            </table>
                          </div>
                        )
                      )
                    : null}
                </td>
              </tr>
            </table>
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmail);
