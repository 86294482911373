import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider
} from 'react-scroll-parallax';
import { LinearProgress, Button } from '@material-ui/core';

import { getCompany } from '../../api/companyDataApi';
import { round } from '../../api/offerCalc';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import RevenueCalc from './RevenueCalc';
import DashboardScreen from './DashboardScreen';
import DocsContent from './DocsContent';
import OtherTools from './OtherTools';
import Team from './Team';

import StaticContent from './StaticContent';

import '../ResidentOnboarding/index.css';
import './index.css';

export function formatNumber(value, precision) {
  let formatted = parseFloat(
    parseFloat(round(value, precision)).toFixed(2)
  ).toLocaleString();

  const valueArr = formatted.split('.');

  if (!precision) {
    formatted = valueArr[0];
  } else if (valueArr[1] && String(valueArr[1]).length < precision) {
    for (let i = String(valueArr[1]).length; i < precision; i++) {
      formatted += '0';
    }
  } else if (!valueArr[1] && precision) {
    formatted += '.';
    for (let i = 0; i < precision; i++) {
      formatted += '0';
    }
  }
  return formatted;
}

export function doCalcs(_calcs) {
  _calcs.CountUnitTurn = _calcs.CountUnit * (_calcs.PercentTurn / 100);
  _calcs.CountTexasUnitTurn =
    _calcs.CountTexasDeregulatedUnit * (_calcs.PercentTurn / 100);

  _calcs.CountUnitEngagement = round(
    _calcs.CountUnitTurn * (_calcs.PercentEngagement / 100),
    0
  );
  _calcs.CountTexasUnitEngagement = round(
    _calcs.CountTexasUnitTurn * (_calcs.PercentEngagement / 100),
    0
  );

  _calcs.TotalCommissionInternet = _calcs.CountUnitEngagement
    ? round(_calcs.CountUnitEngagement * _calcs.AverageCommissionInternet, 2)
    : 0;
  _calcs.TotalCommissionElectric = _calcs.CountTexasUnitEngagement
    ? round(
        _calcs.CountTexasUnitEngagement * _calcs.AverageCommissionElectric,
        2
      )
    : 0;

  _calcs.TotalCommission =
    (_calcs.TotalCommissionInternet ? _calcs.TotalCommissionInternet : 0) +
    (_calcs.TotalCommissionElectric ? _calcs.TotalCommissionElectric : 0);

  _calcs.RevenueShareTotal = round(
    _calcs.TotalCommission * _calcs.PercentRevenueShare,
    2
  );

  _calcs.TotalEngagementPet = round(
    _calcs.CountUnitEngagement * _calcs.PercentPetRegistration,
    0
  );

  _calcs.RevenueSharePet = _calcs.CountUnitEngagement
    ? round(_calcs.TotalEngagementPet * _calcs.SuggestedFeePet, 2)
    : 0;

  _calcs.TotalEngagementVehicle = round(
    _calcs.CountUnitEngagement * _calcs.PercentVehicleRegistration,
    0
  );

  _calcs.RevenueShareVehicle = _calcs.CountUnitEngagement
    ? round(_calcs.TotalEngagementVehicle * _calcs.SuggestedFeeVehicle, 2)
    : 0;

  _calcs.RevenueShareTotalAdditional =
    (_calcs.RevenueSharePet ? _calcs.RevenueSharePet : 0) +
    (_calcs.RevenueShareVehicle ? _calcs.RevenueShareVehicle : 0);

  return _calcs;
}

function CompanyProfile({ siteConfig, webSocket, actions }) {
  const loadProfileInitted = useRef();
  const loadingTimeout = useRef();
  const companyKey = useQueryParam('company', null);
  const ownerKey = useQueryParam('owner', null);
  const [company, setCompany] = useState({});
  const [calcs, setCalcs] = useState(null);

  const [showScreenshotPopup, setShowScreenshotPopup] = useState(null);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      (companyKey || ownerKey) &&
      webSocket.connected &&
      loadProfileInitted.current !== companyKey
    ) {
      loadProfileInitted.current = companyKey;
      loadingTimeout.current = setTimeout(() => {
        actions.pageLoading(true, 'Loading...');
      }, 300);
      getCompany(companyKey, ownerKey).then((_company) => {
        clearTimeout(loadingTimeout.current);
        setCompany(_company);
        actions.pageLoading(false);

        document.title =
          siteConfig.brand + ' + ' + _company.ManagementCompanyName;
      });
    }
  }, [companyKey, webSocket, ownerKey]);

  useEffect(() => {
    if (company) {
      updateCalcs({
        ...company,
        PercentTurn: company.PercentTurn * 100,
        PercentEngagement: company.PercentEngagement * 100
      });
    }
  }, [company]);

  function updateCalcs(_calcsUpdate) {
    setCalcs(doCalcs({ ...calcs, ..._calcsUpdate }));
  }

  return (
    <div
      className={
        'company-page' +
        (company && calcs && !isNaN(calcs.RevenueShareTotal) ? ' initted' : '')
      }>
      {!company || !calcs ? <LinearProgress /> : null}
      <div className="section hero">
        <ParallaxProvider>
          <Parallax speed={-20}>
            <div className="bubbles">
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
              <img src={withPrefix('/images/company/bubble-hero.svg')} />
            </div>
          </Parallax>
        </ParallaxProvider>
        <div className="section-content">
          <div className="hero-content">
            <h1>
              Welcome <strong>{company.ManagementCompanyName}</strong>
            </h1>
            <h2>
              Improve the new resident experience by partnering with{' '}
              {siteConfig.brand} and increase your ancillary income by about{' '}
              <strong style={{ whiteSpace: 'nowrap' }}>
                <span className="sup">$</span>
                {formatNumber(
                  calcs && calcs.RevenueShareTotal
                    ? calcs.RevenueShareTotal
                    : 0,
                  0
                )}
              </strong>{' '}
              per year.
            </h2>

            {calcs ? (
              <RevenueCalc
                siteConfig={siteConfig}
                company={company}
                calcs={calcs}
                updateCalcs={updateCalcs}
                formatNumber={formatNumber}
                tablet
              />
            ) : null}

            <p>And this is how:</p>
            <p>
              Your new residents have a lot to deal with in the time between
              signing their lease and actually moving in to their new home. They
              need to connect utilities, order internet, secure renters
              insurance and so forth.{' '}
            </p>
            <p>
              The {siteConfig.brand} online Resident Dashboard lets them do all
              of this, and much more, all in one convenient place. And when they
              do, oftentimes the provider of those services will pay us a
              commission for processing the order.
            </p>
            <p>We then share 20% of that commission with you.</p>
          </div>

          {calcs ? (
            <RevenueCalc
              siteConfig={siteConfig}
              company={company}
              calcs={calcs}
              updateCalcs={updateCalcs}
              formatNumber={formatNumber}
            />
          ) : null}
        </div>
      </div>

      <StaticContent company={company} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
